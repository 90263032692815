import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Navbar from "../Navbar"
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { baseUrl } from "../Config";
import { Modal } from "react-bootstrap";
import {
    AiOutlineClose,
    AiOutlineEye,
    AiOutlineEyeInvisible,
  } from "react-icons/ai";

function Manifest_start() {
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const [palletdata , setData ] = useState()
    const [pallet , setPAllet] = useState()
    const [error, setError] = useState(false);
    const closePopup = () => setError(false);
    const header ={
        "Content-Type" : "application/json",
        "Authorization" : `Token ${isAuth.token}`
    }


    useEffect(() => {
        if (!isAuth) {
            navigate("/")
        }
    }, [])
    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
    };

    const handlePallet = (e) => {
        setPAllet(e.target.value)
    }

    const handlemsg = () => {
        toast("PLEASE ENTER PALLET ID")
    }
    const handleSubmit = () =>{
        const obj = {"data":{"serial_number": pallet,"encrypted":false},"encoded_data":"yes"}
        axios.post(baseUrl + '/item/get_serialnumber/',obj,{"headers" : header})
        
  
        .then((response) => {
            setData(response.data.data)


            navigate("/manifeststop",{"state":pallet})
            
        },(error) => {
 

            setError(true)
          }
        )
        
    }
  

    return (
        <div>
        <Navbar />
        <section className="inner">
                <div className="adminLog">
                    <div className="loginBLock">
             <div className="row">
                <div className="col-md-10 col-10"></div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
                        <center>
                          
                                <div className="imgg">
                            <img src="/mainfest_grey.png" className="im" /> <br></br><br></br>
                                <h5 className="in">ENTER PALLET ID</h5>
                                </div>
                                <p>
                                        <input onChange={handlePallet} type="text"
        pattern="[0-9]*" className="form-control input-sm" id="email"
                                           
                                            placeholder="Enter Pallet id (only number)" />
                                        <i className="far" id="togglePassword"></i>
                                        <button onClick={handleReset} className="imer" >
                                            <img src="/text_clear.png" className="imr" position="absolute"
                                            ></img></button>
                                    </p>
                                   
                                <div className="row">
                            <div className="col-md-4 col-4"></div>
                            <div className="col-md-4 col-4">
                                <div >
                                {
                                       pallet ? 
                               
                                <button onClick={handleSubmit} type="button" className="but" ><p className="io">Start</p></button>
                               :
                               <Link onClick={handlemsg} to=''> <button type="button" className="but" ><p className="io">Start</p></button></Link>
                            }
                                <ToastContainer />
                                </div>
                                </div> 
                                </div>
                        
                        </center>
                        {/* <center>
              
                            <div className="imgg">
                                <img src="/mainfest_grey.png" className="im" />
                                <h5 className="in">ENTER PALLET ID</h5>
                                <div >
                                  
                                  <div className="inpu">
                                    <p>
                                        <input onChange={handlePallet} type="text"
        pattern="[0-9]*" className="form-control input-sm" id="email"
                                           
                                            placeholder="Enter Pallet id (only number)" />
                                        <i className="far" id="togglePassword"></i>
                                        <button onClick={handleReset} className="imer" >
                                            <img src="/text_clear.png" className="imr" position="absolute"
                                            ></img></button>
                                    </p></div>
                                   
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-md-4 col-4"></div>
                            <div className="col-md-4 col-4">
                                <div >
                                {
                                       pallet ? 
                               
                                <button onClick={handleSubmit} type="button" className="but" ><p className="io">Start</p></button>
                               :
                               <Link onClick={handlemsg} to=''> <button type="button" className="but" ><p className="io">Start</p></button></Link>
                            }
                                <ToastContainer />
                                </div>
                                </div> 
                                </div>
                        </center> */}
                      
                    </div>
                </div>
            </section>
            <Modal show={error} onHide={closePopup}>
          <div className="popup-content error">
            <span onClick={closePopup}>
              <AiOutlineClose />
            </span>
            <div>
            <h3>This Pallet id does not exist</h3>
             
</div>
          </div>
        </Modal>
        </div>
    )
}
export default Manifest_start