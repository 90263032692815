import logo from './logo.svg';
import './App.css';
import './main.css'
import Router from "./component/Router"
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-toastify/dist/ReactToastify.css';


function App() {
  
  return (
    <div className="App">
       <Router />
       
    </div>
  );
}


export default App;
