import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Navbar from "../Navbar"
import Printpage from "../Printpage"
import { ToastContainer, toast } from 'react-toastify';
import { baseUrl } from "../Config"


function Print() {
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const location = useLocation()
    const data = location.state
    const obj = {"data":{"encrypted":false},"encoded_data":"yes"}
    const [list , setList] = useState()
    


    
    useEffect(() => {       
        if(!isAuth) {
            navigate("/")
        }
    }, [])

    const header ={
        "Content-Type" : "application/json",
        "Authorization" : `Token ${isAuth.token}`
    }

    

    //get list of categories
    useEffect( ()=> {
        axios.post(baseUrl + '/category/list/' ,obj,{"headers" : header})
        .then(response => setList(response.data.data))
    },[])

    

    let searched_data = list?.find(o => o.value === data?.text);
    
    const generate_obj = {"data": {"product": searched_data?.id,"product_name": searched_data?.value,"warehouse_id": "DC001","encrypted": false}, "encoded_data": "yes" , "discription":searched_data?.description }
    


    const handlePrint = () => {
        

    }

    const notify = () => toast("Wow so easy !");
    


    return (
        <div>
            
            <Navbar />
            <section className="inner">
                <div className="adminLog">
                    <div className="loginBLock">
                    <div className="row">
                <div className="col-md-10 col-10"></div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/new"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div> <center><div className="row">
                            <div className="col-md-3">
                            </div>
                            <div className="col-md-6" >
                                <a  className="l">
                                    <div className="printt height-b" style={{backgroundColor:data.color}}>
                                        <center>
                                            <h5 className="misc">{data.text}</h5></center>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-3">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-4"></div>
                            <div className="col-md-4 col-4">
                                <div >
                            <Link to='/barcode' state={{generate_obj}} onClick={handlePrint}><button className="pri">Print</button></Link>
                        </div>
                        </div>
                                </div>  
                        </center>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Print