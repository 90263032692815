import { useEffect, } from "react"
import { Link, useNavigate } from "react-router-dom"
import Navbar from "../Navbar"
import axios from 'axios'

function New() {

    const isAuth = JSON.parse(localStorage.getItem('user'))
    var name = []
    var color = []
    var objs = { name, color }
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuth) {
            navigate("/")
        }
    }, [])

    return (
        <div>
            <Navbar />
            <section className="inner">
                <div className="adminLog">
                    <div className="loginBLock">
             <div className="row">
                <div className="col-md-10 col-10"></div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
                
             
<center>
                        <div className="row mar-t">
                            <div className="col-md-4 col-4">
                            <Link to="/print" state={{text:"TGT-HC" , color : "#FFFF00"}}>
                                <button type="button" className="wh wir" ><p className="io">TGT-HC</p></button>
                                </Link>
                                </div>
                                <div className="col-md-4 col-4">
                            <Link to="/print" state={{text:"TGT-GM" , color : "#007FFF"}}>
                                <button type="button" className="wh wiw"><p className="io">TGT-GM</p></button></Link>
                            </div>

                            <div className="col-md-4 col-4">
                            <Link to="/print" state={{text:"TGT-SH" , color : "#FF00FF"}}>
                                <button type="button" className="wh wia"><p className="io">TGT-SH</p></button></Link>
                            </div>
                           
                        </div></center>
                        <center>
            <div className="row mar-t">
            <div className="col-md-4 col-4">
                                <Link to="/print" state={{text:"TGT-AP" , color : "#22B14C"}}>
                                    <button type="button" className="wh wib"><p className="io">TGT-AP</p></button></Link>
                            </div>

                            <div className="col-md-4 col-4">
                            <Link to="/print" state={{text:"TGT-UG" , color : "#00FFFF"}}>
                                <button type="button" className="wh wic"><p className="io">TGT-UG</p></button></Link>
                            </div>

                            <div className="col-md-4 col-4">
                            <Link to="/print" state={{text:"MISC" , color : "#FF0000"}}>
                                <button type="button" className="wh wid" ><p className="io">MISC</p></button></Link>
                            </div>
            </div>
            </center>
            
                       
                     
                    </div>
                </div>
            </section>
        </div>
    )
}
export default New