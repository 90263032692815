import React, { useState } from 'react';

const serialNumbers = [
  { number: '001', active: true },
  { number: '002', active: false },
  { number: '003', active: true },
  { number: '004', active: false },
];

function Logo() {
  const [showInactive, setShowInactive] = useState(false);

  const filteredSerialNumbers = showInactive
    ? serialNumbers
    : serialNumbers.filter((serialNumber) => serialNumber.active);

  return (
    <div>
      <button onClick={() => setShowInactive(!showInactive)}>
        {showInactive ? 'Hide Inactive' : 'Show Inactive'}
      </button>
      <ul>
        {filteredSerialNumbers.map((serialNumber) => (
          <li key={serialNumber.number}>{serialNumber.number}</li>
        ))}
      </ul>
    </div>
  );
}

export default Logo