import React, { useState } from 'react'
import axios from 'axios'
import { useEffect } from "react"
import { useNavigate } from "react-router";
import { AiOutlineEye, AiOutlineEyeInvisible, } from "react-icons/ai";
import { baseUrl } from './Config.js'
import { toast, ToastContainer } from 'react-toastify';

function Login() {
  var user = {}
  user.user_type = "1"
  user.user_type="2"
  const [email, setUser] = useState()
  const [password, setPass] = useState()
  const [response, setResponse] = useState()
  var navigate = useNavigate()
  var check
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState(null)
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const isAuth = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        if (isAuth) {
            navigate("/home")
        }
    }, [])
  const [passwordShow, setPasswordShow] = useState(false);
  function getemail(e) {
    setUser(e.target.value)
  }
  function getpass(e) {
    setPass(e.target.value)
  }
  const emailv = (e) => {
    setError('')
  }
  const passv = (e) => {
    setErrors('')
  }
  function getdata() {


    if (!email && !password) {
      setError('User ID is Required');
      setErrors('Password is Required');
    }
    else if(!password) {
      setErrors('Password is Required');
    }
    else if(!email) {
      setError('User ID is Required');
      setErrors('Password must 8 chars');
    }



    var obj = {
      "data": {

        "email": email,
        "password": password,

        "encrypted": false

      }, "encoded_data": "yes"
    }


    axios({
      url: baseUrl + "/login/",
      method: 'post',
      data: obj
    }).then((response) => {
    
      check = response.data.data
      console.log(response)
     
   
      if (check.token && check.user_type != 1  && check.status == 1) {
        localStorage.setItem("user", JSON.stringify(response.data.data))
        // localStorage.setItem("usertype","associate")
        navigate("/home")

      }

      else {
        toast("User is not Active")
  
        setResponse(response.data.message)
      }
    }, (error) => {

      if (password) {
        toast("Invalid Credentials")
      }
     
    }
    )
  }
  const togglePasswordVisiblity = () => {
    setPasswordShow(!passwordShow);
  };
  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      getdata();
    }
  };


  return (
    <div>
      <div className="inner-bg">
        <div className="adminLogin" >
          <h2>
            <form className='for'>
              <div className='admin'>
                <center>
                  <img src="logo.png" />
                  <div className="m-3">
                    <p>
                    </p>
                  </div><br></br>
                  <h2 className='lo'>LOGIN</h2>
               <br></br>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input onKeyPress={handleKeypress} required type="email" onClick={emailv} className="form-control input-smm"
                        id="email" placeholder="User ID" onChange={getemail}  />
                    </div>{error && <div className='erorr'>
                      {error}</div>}
                  </div>
                </div>
         
                <div>
                  <div className="row">
                    <div className="form-group">
                      <div>
                        <p>
                          <input 
                            type={passwordShow ? "text" : "password"} name="password" onKeyPress={handleKeypress}
                            onClick={passv} onChange={getpass} placeholder="Password" className="form-control input-smmm" autoClsomplete="on" />
                          <i className="far fa-eye toggle" id="togglePassword"></i>
                          <i onClick={togglePasswordVisiblity} position="absolute">
                            {passwordShow ? (
                              <AiOutlineEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </i>
                        </p>
                      </div>{errors && <div className='eror'>{errors}</div>}
                    </div>
                  </div>
                </div>
            
               <button type="button" name="button" onClick={getdata} className="buton">Login</button>
                </center>
              </div>
            </form></h2>
            <ToastContainer />
        </div>
      </div>
    </div>
  )
}
export default Login




