import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from "./Login"
import Main from "./Main"
import New from "./Inner/New"
import Print from "./Inner/Print"
import Manifest_start from "./Inner/Manifest_start"
import Manifest_stop from "./Inner/Manifest_stop"
import Reprint from "./Inner/Reprint"
import Logo from "./Inner/Logo"
import Remove from "./Inner/Remove"
import Proceed from "./Inner/Proceed"
import Download from "./Inner/Download"
import Targetapi from "./Targetapi"
import Printpage from "./Printpage"
import Reprintt from "./Reprintt"
import Download_list from "./Inner/Download_list"
import PageNotFound from "./PageNotFound"
import InventoryList from "./Inner/InventoryList"



function Router(){
    return (
        <BrowserRouter>
            <Routes>


                <Route path="/" element={<Login />}></Route>
                <Route path="/logo" element={<Logo />}></Route>
                <Route path="*" element={<PageNotFound />}></Route>
                <Route path="/home" element={<Main />}></Route>
                <Route path="/barcode" element={<Printpage />}></Route>
                <Route path="reprint/barcode" element={<Reprintt />}></Route>
                <Route path="/new" element={<New />}></Route>
                <Route path="/print" element={<Print />}></Route>
                <Route path="/manifeststart" element={<Manifest_start />}></Route>
                <Route path="/manifeststop" element={<Manifest_stop />}></Route>
                <Route path="/reprint" element={<Reprint />}></Route>
                <Route path="/remove" element={<Remove />}></Route>
                <Route path="/proceed" element={<Proceed />}></Route>
                <Route path="/download" element={<Download />}></Route>
                <Route path="/downloadlist" element={<Download_list />}></Route>
                <Route path="/inventorylist" element={<InventoryList />}></Route>
                <Route path="/data" element={<Targetapi/>}></Route>
               

            </Routes>
        </BrowserRouter>
    )
}
export default Router