import React, { useEffect, useState } from 'react'
import { useBarcode } from 'next-barcode';
import {  Link, useLocation  } from "react-router-dom"
import axios from 'axios';
import Navbar from "./Navbar"
import { baseUrl } from './Config';


function Printpage() {


    const user = JSON.parse(localStorage.getItem('user'))
    const location = useLocation()
    const data = location.state
    const [barcode_data , setData ] = useState()


    const header ={
      "Content-Type" : "application/json",
      "Authorization" : `Token ${user.token}`
  }

    var  {inputRef}  = useBarcode({
        value: barcode_data?.serial_number,
        options: {
          background: 'white',
          displayValue: false,
          width : "4",
          height:"150"
        }
      });

    function prnt() {
       window.print()
    }

    function navigate() {
      window.location = '/new'
   }

  setTimeout(navigate , 1000)


    

    useEffect(() => {
      axios.post(baseUrl + '/item/generate_serialnumber/', data.generate_obj , {"headers" : header})
      .then(response => setData(response.data.data))
      .then(setTimeout(prnt , 600))
      
      
    } , [])




    
    
      

  return (         
    

                            <div className='row'>
                            <div className='size'>
                            <center>
                            <table className='section-to-print'>
                        
                              <tbody>
                               <center>
                                <tr><b><h1 className='headng'>{barcode_data?.product_name}</h1></b></tr>
                                <tr><svg ref={inputRef} /></tr>
                                <tr><b><h1>{barcode_data?.serial_number}</h1></b></tr>
                                <tr><b><h1>{data.generate_obj?.discription}</h1></b></tr>
                                </center>
                                </tbody>
                               
                            </table>
                            </center>
                            </div>
                            </div>
                          
                            
  )
}

export default Printpage