import React from 'react'

function Pagination({ nPages, currentPage, setCurrentPage }) {


    if (Number.isNaN(nPages)){
        var pageNumbers = [...Array(1).keys()].slice(1)
    }
    else{
        var pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    }

    

    const nextPage = () => {
        if(currentPage !== nPages) setCurrentPage(currentPage + 1)
}
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }

 

    
  return (
    <nav>
    <ul className='pagination justify-content-center'>
        <li className="page-item">
            <a className="page-link"
                onClick={prevPage}>
                
                Previous
            </a>
        </li>

        

        
                <div className="dropdown">
                <button className="dropbtn">Pages <b>&#8250;</b></button> 
                <div className="dropdown-content">      
        {pageNumbers.map(pgNumber => (
            <li key={pgNumber} 
                className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                 <a onClick={() => setCurrentPage(pgNumber)}  
                    className='page-link'>
                    {pgNumber}
                </a> 
                
                
                    
                
                
            </li>
        ))}
        </div>
         </div>
        
        <li className="page-item">
            <a className="page-link"
                onClick={nextPage}
                href='#'>
                Next
            </a>
        </li>
        
    </ul>
</nav>
  )
}

export default Pagination