import React, { useEffect, useState } from 'react'
import {Link} from "react-router-dom"
import { useNavigate } from "react-router";
import Navbar from "./Navbar"
function Main() {

    const user = JSON.parse(localStorage.getItem('user'))
    var navigate = useNavigate()
    var i = 1
    
    useEffect(() => {
        if (!user){
            navigate("/")
        }
    },[])

    return (
        <div>
            <Navbar />
            <section className="inner">
                <div className="container">






                    {
                        user?.user_type_value == "Manager" ?
                            <>


                                <div className="row mar-t ">
                                    <div className="col-md-1">
                                    </div>
                                    <div className="col-md-5">
                                        <Link className="l" to="/new">
                                            <div className="new height-b mb-4">
                                                <center><img src="/New.png" className="ne" />
                                                    <h5 className="n">NEW</h5></center>
                                            </div>
                                        </Link>
                                    </div>



                                    <div className="col-md-5">
                                        <Link to="/manifeststart" className="l" >
                                            <div className="manifest height-b mb-4">
                                                <center><img src="/manifest.png" className="ne" />
                                                    <h5 className="n">MANIFEST</h5></center>
                                            </div>
                                        </Link>
                                    </div>

                                    
                                </div>



                                <div className="row">
                                    <div className="col-md-1">
                                    </div>
                                    <div className="col-md-5">
                                        <Link to="/reprint" className="l">
                                            <div className="reprint height-b mb-4">
                                                <center><img src="/Reprint.png" className="ne" />
                                                    <h5 className="n">REPRINT</h5></center>
                                            </div>
                                        </Link>
                                    </div>


                                    <div className="col-md-5">
                                        <Link to="/remove" className="l">
                                            <div className="remove height-b mb-4">
                                                <center><img src="/remove.png" className="ne" />
                                                    <h5 className="n">REMOVE</h5></center>
                                            </div>
                                        </Link>
                                    </div>
                                </div>



                                <div className="row ">
                                    <div className="col-md-1">
                                    </div>
                                    <div className="col-md-5">
                                        <Link to="/download" className="l">
                                            <div className="download2 height-b mb-4">
                                                <center><img src="/download.png" className="ne" />
                                                    <h5 className="n">DOWNLOAD</h5></center>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="col-md-5">
                                        <Link to="/inventorylist" className="l">
                                            <div className="inventory height-b mb-4">
                                                <center><img src="/inventory.png" className="ne" />
                                                    <h5 className="n">INVENTORY</h5></center>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </>
                            :
                            ""
                    }


                    {
                        user?.user_type_value == "Supervisor" ?
                            <>
                                <div className="row mar-t ">
                                    <div className="col-md-2">
                                    </div>
                                    <div className="col-md-4">
                                        <Link className="l" to="/new">
                                            <div className="new height-b mb-4">
                                                <center><img src="/New.png" className="ne" />
                                                    <h5 className="n">NEW</h5></center>
                                            </div>
                                        </Link>
                                    </div>


                                    <div className="col-md-4">
                                        <Link to="/manifeststart" className="l" >
                                            <div className="manifest height-b mb-4">
                                                <center><img src="/manifest.png" className="ne" />
                                                    <h5 className="n">MANIFEST</h5></center>
                                            </div>
                                        </Link>
                                    </div>
                                </div>





                                <div className="row">
                                    <div className="col-md-2">
                                    </div>
                                    <div className="col-md-4">
                                        <Link to="/reprint" className="l">
                                            <div className="reprint height-b mb-4">
                                                <center><img src="/Reprint.png" className="ne" />
                                                    <h5 className="n">REPRINT</h5></center>
                                            </div>
                                        </Link>
                                    </div>


                                    <div className="col-md-4">
                                        <Link to="/remove" className="l">
                                            <div className="remove height-b mb-4">
                                                <center><img src="/remove.png" className="ne" />
                                                    <h5 className="n">REMOVE</h5></center>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </>
                            :
                            ""
                    }



                    {
                        user?.user_type_value == "Associate" ?
                            <>

                                <div className="row mar-t ">
                                    <div className="col-md-2">
                                    </div>

                                    <div className="col-md-4">
                                        <Link className="l" to="/new">
                                            <div className="new height-b mb-4">
                                                <center><img src="/New.png" className="ne" />
                                                    <h5 className="n">NEW</h5>
                                                </center>
                                            </div>
                                        </Link>
                                    </div>


                                    <div className="col-md-4">
                                        <Link to="/manifeststart" className="l" >
                                            <div className="manifest height-b mb-4">
                                                <center><img src="/manifest.png" className="ne" />
                                                    <h5 className="n">MANIFEST</h5></center>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-2">
                                    </div>
                                    <div className="col-md-4">
                                        <Link to="/reprint" className="l">
                                            <div className="reprint height-b">
                                                <center><img src="/Reprint.png" className="ne" />
                                                    <h5 className="n">REPRINT</h5></center>
                                            </div>
                                        </Link>
                                    </div>

                                </div>
                            </>
                            :

                            ""
                    }



                </div>
            </section>

        </div>



    )
}
export default Main