import { useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Navbar from "../Navbar"

import { ToastContainer, toast } from 'react-toastify';

function Proceed() {
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const msg = localStorage.getItem('msg')
    const pallet = localStorage.getItem('pallet')
    // const location = useLocation()
    // const data = location.state
  
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuth) {
            navigate("/")
        }

        if (!pallet) {
            navigate("/remove")
        }
    }, [])

    toast(msg)

    function removelocal() {
        localStorage.removeItem("pallet")
        localStorage.removeItem("msg")
    }

    setTimeout (removelocal, 2000)
   
   
    return (
        <div>
            <Navbar />
            `   <section className="innerrr" >
                <div className="adminLog">
                    <div className="loginBLock">
                    <div className="row">
                <div className="col-md-10 col-10"></div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div><br></br><br></br><br></br>
                        <div>
                            <center>
                                <div>
                                    <img src="/delete.png" className="im" />
                                    <h5 className="ite"> item removed,logs saved
                                        <br></br>Pallet ID : {pallet}</h5>
                                    <div><br></br>
                                    </div>
                                </div>
                            </center>
                        </div>
                     
                    <ToastContainer/>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Proceed