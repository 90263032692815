import axios from 'axios';

import React, { useEffect, useState } from 'react'
import { baseUrl } from './Config';

function Targetapi() {

    const [dataa ,setData] = useState("");
    const [barcode ,setCode] = useState("");
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const url = baseUrl + "/item/get_item/"

    const decodedText = document.createElement('textarea');
    decodedText.innerHTML = JSON.stringify(dataa);
    const data = JSON.parse(decodedText.value);

    const header ={
      "Content-Type" : "application/json",
      "Authorization" : `Token ${isAuth.token}`
  }

    const obj = {
      "data": {
          "item_barcode": barcode,
          "encrypted": false
      },
      "encoded_data": "yes"
  }
    


    const handleChange = (e) => {
        
        setCode(e.target.value)


    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const response = axios({
            url: url,
            method: 'post',
            headers: header,
            data: obj
          }).then((response) => {
            setData(response.data.data)

          })

    }



  return (
<>
<div className='row mt-4'>
<div className='col-md-4'></div>
<div className='col-md-4'>

    <form>
  <div className="mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">Enter Barcode Number</label>
    <input type="number" onChange={handleChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
  </div>
 
  <button onClick={handleSubmit} type="submit" className="btn btn-primary">Submit</button>
</form>
</div>
</div>


<div className="container">
  <table className="table table-hover">
    <thead>
      <tr>
        <th scope="col">Key</th>
        <th scope="col">Value</th>
        
      </tr>
    </thead>
    <tbody>
      <tr className="table-active">
        <th scope="row">uid</th>
        <td>{data.uid}</td>
       
      </tr>
      <tr>
        <th scope="row">id</th>
        <td>{data.id}</td>
      </tr>

      <tr>
        <th scope="row">item_barcode</th>
        <td>{data.item_barcode}</td>
      </tr>

      <tr>
        <th scope="row">tgt_title</th>
        <td>{data.tgt_title}</td>
        
      </tr>


      <tr>
        <th scope="row">tgt_brand</th>
        <td>{data.tgt_brand}</td>
      </tr>

      <tr>
        <th scope="row">tgt_category</th>
        <td>{data.tgt_category}</td>
      </tr>


      <tr>
        <th scope="row">tgt_model</th>
        <td>{data.tgt_model}</td>
      </tr>


      <tr>
        <th scope="row">tgt_image</th>
        <td>{JSON.stringify(data.tgt_image)}
        </td>
      </tr>


      <tr>
        <th scope="row">tgt_color</th>
        <td>{data.tgt_color}</td>
      </tr>

      <tr>
        <th scope="row">tgt_size</th>
        <td>{data.tgt_size}</td>
      </tr>

      <tr>
        <th scope="row">tgt_weight</th>
        <td>{JSON.stringify(data.tgt_weight)}
        </td>
      </tr>

      <tr>
        <th scope="row">tgt_dimensions</th>
        <td>{JSON.stringify(data.tgt_dimensions)}
        </td>
      </tr>

      <tr>
        <th scope="row">targetowned</th>
        <td>
        {data.targetowned ? "True" : "False"}
        </td>
      </tr>

      <tr>
        <th scope="row">tgt_msrp</th>
        <td>{data.tgt_msrp}</td>
      </tr>

      <tr>
        <th scope="row">tgt_saleprice</th>
        <td>{data.tgt_saleprice}</td>
      </tr>

      <tr>
        <th scope="row">date</th>
        <td>{data.date}</td>
      </tr>
     
    </tbody>
  </table>
</div>



    </>
  )
}

export default Targetapi