import { useState ,useEffect} from 'react';
import Navbar from '../Navbar';
import { Link, Navigate, useNavigate } from "react-router-dom"
import React from "react";
import { DateRange } from 'react-date-range';
import axios from 'axios';
import { baseUrl } from '../Config';
import { CSVLink } from 'react-csv';

function Download3() {
  var navigate = useNavigate()
  const [date, setDate] = useState(new Date());
   const [list,setList] = useState([])
   const [data,setData] = useState()
 const [data_new,setDatanew] = useState([])
 const [downloaded,setDownl] = useState(false)
 const [exportt,setExpo] = useState()
 let today = new Date();
 
 let currentYear = today.getFullYear();
 let currentMonth = today.getMonth();
 let currentDay = today.getDate();

 let min = new Date(currentYear, currentMonth, 1)
//sets the max.


  const isAuth = JSON.parse(localStorage.getItem('user'))
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
 const datee = state[0].startDate.getFullYear() + '-' + (state[0].startDate.getMonth() + 1) + '-' + state[0].startDate.getDate();
 const dateee = state[0].endDate.getFullYear() + '-' + (state[0].endDate.getMonth() + 1) + '-' + state[0].endDate.getDate();

  
  const body = {"data":{"from_date":datee ,"to_date":dateee,"encrypted":false},"encoded_data":"yes"}
  const header ={
    "Content-Type" : "application/json",
    "Authorization" : `Token ${isAuth.token}`
}

   const download = async (e) => {
    e.preventDefault()
   
    await axios.post(baseUrl + '/item/filter_manifest/' , body , {"headers" : header})
    .then(response => {
      setList(response.data.data)
    })
    
      
    
    
    
    
}
useEffect(()=> {
  if(list.length >= 1) {
    navigate('/downloadList' ,{"state" : list})
  }
  
})

 

const changeState =() => {
  setExpo(0)
}

const changeState1 =() => {
  setExpo(1)
}


const getFile = (e) => {
  
  

  const file = e.target.files[0]



  const header ={
    "Content-Type" : "application/json",
    "Authorization" : `Token ${isAuth.token}`
  }
  

  var formData = new FormData(); 
  formData.append("itemsheet", file);
  formData.append("encrypted", false);
  
  axios.post(baseUrl + '/item/filter_item_list/' , formData , {"headers" : header} )
  .then(response => {
  
    setData(response.data.data.data)
    response.data.data.data.forEach(item => setDatanew(prev=> [...prev , item.item ]))
    
  })


}







// if (data_new && downloaded === false)  {

  const headerss = [
    { label: "serialnumber", key: "serial_number" },
    { label: "product_name", key: "product_name" },
    { label: "barcode", key: "item_barcode" },
    { label: "title", key: "tgt_title" },
    { label: "msrp", key: "tgt_msrp" },
    { label: "category", key: "tgt_category" },
    { label: "brand", key: "tgt_brand" },
  ];
 
  const csvReport = {
    data: data,
    headers: headerss,
    filename: 'data.csv'
  };




  return (
    <div>
      <Navbar />
      <section className="inner">
        <div className="adminLog">
          <div className="loginBLock">
            <div >
              <div className="nav">
                <Link to="/home"><img className="clo" src="/close.png" /></Link>
              </div><h5 className="ine">SELECT DATE</h5>
            </div>
            <center>
              <div className='app'>
                <div className='calendar-container'>
                  <div className=''>

                  
                <div onClick={changeState} style={{"marginTop":"60px"}}  className='cold-md-12 col-12 pick'>
                    <DateRange editableDateInputs={true}
                      onChange={item => setState([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                      minDate = {min}
            
               
                     />
                  </div>
                  </div>
                </div>
              </div> </center> <div className="row">
              <div className="col-6">
                <div className=" browse-profile-cont margin-bottom-30">
                  <div className="input-style">
                    <input className="inputUpload" type="text" placeholder="Choose File" />
                    <img src="/upload.png" className="clee"></img>
                  </div>
                  <input onClick={changeState1}  onChange={getFile} type="file" className="browse-profile"/>
                </div></div>
                <div className="col-md-6 col-6" style={{"textAlign" : "-webkit-left"}}>
                {
                  (exportt === 1 && data_new) ? 
                  <CSVLink filename='data.csv' data={data_new} headers={headerss}><button type="button" style={{"width" : "245px"}}  className="buty"><p className="io">Download</p></button></CSVLink>
                  :
                  <button type="button" onClick={download} style={{"width" : "245px"}} className="buty"><p className="io">RUN</p></button>
                }
               
               
               </div>
                
                
                
                </div>
          </div>
                
        </div>
    
      </section>
    </div>
  )
}
export default Download3