import { Link, useNavigate } from "react-router-dom"
function Navbar() {
    const handleLogout = () => {
        localStorage.removeItem('user')
    }
    let userdataa = JSON.parse(localStorage.getItem('user'))
  
    if (userdataa?.token) {
        return (
            <div>
                <nav className="navbar navbar-expand-lg white">
                    <div className="container-fluid sm" >
                        <div className="row">
                            <div className="col-md-1 col-1 marg">
                                <a className="navbar-brand" >
                                    <div className="test">
                                    <img className="text" src="/user1.png" />
                                    </div>
                            <div>
                                <p className="row-bn">{userdataa.username}</p><br></br>
                                </div>
                                <div>
                                <p className="row-b">User ID : {userdataa.user_code}</p>
                            </div>
                                    </a>
                        </div>
                        <div className="col-md-11 mt-2 col-11 marg" style={{"textAlign" : "-webkit-right"}}>
                        <Link  onClick={handleLogout} to="/"> <button className="logout" >
                        <p className="logout-bn" style={{"color":"white"}}>LOG OUT</p>
                        <div className="logout-bnn">&#8250;</div></button>
                    </Link>
                    </div>
                    </div>
                    </div>
                </nav>
            </div>
        )
    }
}
export default Navbar