import React from 'react'
import Navbar from '../Navbar'
import { Link, useLocation } from "react-router-dom"
import { useState, useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios';
import { baseUrl } from '../Config';
import { toast, ToastContainer } from 'react-toastify';



function Download_list() {
  const isAuth = JSON.parse(localStorage.getItem('user'))


  
  const [csvdata , setCsv ] = useState([])
  const location = useLocation()
  const data = location.state
  
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedd, setChecked] = useState([])
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [newchecked ,setNew] = useState([])
  const [dataa,setData] = useState()
  const [data_new,setDatanew] = useState([])
  const [is_processed, setProcess] = useState(false);
  const [len,setLen ] = useState('')




  const toggleCheck1 = (e) => {



    const {value , checked } = e.target


    if (checked === true ){
      setNew([...newchecked , [Number(value)]])
      setChecked([...checkedd , Number(value)])
    }else{
      
      setNew(newchecked.filter((e) =>  e[0] !== Number(value) ))     
      setChecked(checkedd.filter((e) =>  e !== Number(value) ))

      
    } 
    
   


  }



  


  useEffect(() => {
    setList(data);

  }, [list]);



  const selectAll = (value) => {
    
    const checked = value
  
    setCheckedAll(value);


    if (checked === true ){
      data.map(item => {

        setNew(prev=> [...prev , [item.serial_number]])
        setChecked(prev=> [...prev , item.serial_number])
      })
    }else{
      data.map(item => {
        setChecked("")
        setNew("")
      })
      
    }

    
    // setChecked((prevState) => {
   
    //   const newState = { ...prevState };
    //   for (const inputName in newState) {
    //     newState[inputName] = value;
    //   }
    //   return newState;
    // });
  };

  // useEffect(() => {
  //   let allChecked = true;
  //   for (const inputName in checkedd) {
  //     if (checkedd[inputName] === false) {
  //       allChecked = false;
  //     }
  //   }
  //   if (allChecked) {
  //     setCheckedAll(true);
  //   } else {
  //     setCheckedAll(false);
  //   }
  // }, [checkedd]);


  






  const handleApi = (e) => {
  

  
  
    const header ={
      "Content-Type" : "application/json",
      "Authorization" : `Token ${isAuth.token}`
    }

    const obj = {"data":{"serial_number_list":checkedd,"encrypted":false},"encoded_data":"yes"}


    
  
    
    axios.post(baseUrl + '/item/filter_item/' , obj , {"headers" : header} )
    .then(response => {

      console.log(response.data.data,"daadadadadata")
     
      setData(response.data.data)
      setLen(response.data.data.length)
      response.data.data.forEach(item => {
        console.log()
        if (Object.keys(item.item).length > 0) {

          console.log(item,"ITEM")
          const decodedText = document.createElement('textarea');
          decodedText.innerHTML = JSON.stringify(item);
          const data_item = JSON.parse(decodedText.value);
          console.log(data_item.item,"di")
          const barcode =  data_item.item.item_barcode
          const chr = '0'
          var newbar= chr.concat(barcode);
          data_item.item['item_barcode'] = newbar
          setDatanew(prev=> [...prev , data_item.item ])


        }
        
      
      })
      setProcess(true)      
      
    })
   
  
  
  }
  console.log(is_processed,"tetetete",dataa?.length, len ,data_new?.length)

  
  if (is_processed === true && dataa?.length === len && data_new?.length > 0) {
    
    setTimeout(csv_down,2000)
  }

  function csv_down() {
    const button = document.getElementById('csv_but')
    button?.click()
    setProcess(false)
  }

  
  const headerss = [
    { label: "serialnumber", key: "serial_number" },
    { label: "product_name", key: "product_name" },
    { label: "barcode", key: "item_barcode" },
    { label: "title", key: "tgt_title" },
    { label: "msrp", key: "tgt_msrp" },
    { label: "category", key: "tgt_category" },
    { label: "brand", key: "tgt_brand" },
  ];

  const csvReport = {
    data: dataa,
    headers: headerss,
    filename: 'data.csv'
  };




  return (
<div>
      <Navbar />
      <section className="inner">
        <div className="adminLog">
          <div className="loginBLock">
            
            <div className="row">
                <div className="col-md-10 col-10">
                <div><h5 className="ine">MANIFEST DL DOWNLOAD</h5></div>
                </div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
           
              
          
          <div className='row'>
           
       
   
            
              <div className="col-md-2 col-2-sm">
                <input className="form-check-input" type="checkbox" onChange={(event) => selectAll(event.target.checked)}
                  checked={checkedAll} />
                   </div>
                  
              <h5 className='select'>Select All</h5>
             
             
              
              </div>
              <div className='row'>
            <div className='col-md-2 col-2'></div>

   
            <div>
              <div>

              {
                data.map(item => <div className="form-check form-check-inline" >
                <input className="form-check-input" type="checkbox" name={item.serial_number}  value={item.serial_number}
                  onChange={toggleCheck1}
                  // checked={checkedd[item.serial_number]}
                  checked={checkedd.includes(item.serial_number)} />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {item.serial_number}
                  
                </label>
             </div>)
              }
                 </div>
              <div className='col-md-2 col-2'></div>
              </div>

            </div>
           


            <center>
            {/* <Link to="/download"><button type="button" className="butyy"><p className="io">DOWNLOAD</p></button></Link> */}
            {/* <CSVLink filename={"manifest-filter.csv"} data={newchecked}><button type="button" className="butyy"><p className="io">DOWNLOAD</p></button></CSVLink> */}
            
            <button onClick={handleApi}  type="button" className="butyy"><p className="io">Download</p></button>

              <CSVLink hidden id="csv_but" filename='data.csv' data={data_new} headers={headerss}><button type="button" style={{"width" : "245px"}}  className="buty"><p className="io">Download</p></button></CSVLink>
            
            </center>
            


            {/* <Link to="/download"><button type="button" className="butyy"><p className="io">DOWNLOAD</p></button></Link>
            <CSVLink filename={"manifest-filter.csv"} data={newchecked}><button type="button" className="butyy"><p className="io">DOWNLOAD</p></button></CSVLink> */}
            
            
            
            </div>
        </div></section>
        

    </div>
  )
}

export default Download_list